import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const LargeProductDetailsContainer = styled.div`
  position: relative;
  display: flex;
  background: ${STATIC_COLORS.base.white};
`;

export const LargeProductImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 548px;
  max-width: 548px;
  min-height: 706px;
  overflow: hidden;
`;

export const LargeProductImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 548px;
`;

export const LargeTopPromoIcon = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: -36px;
  width: 155px;
  height: 39px;
  padding: 10px 0;
  background-color: ${STATIC_COLORS.base.red};
  transform: rotate(-45deg);

  span {
    text-transform: uppercase;
  }
`;

export const WishListCtaWrapper = styled(Flexbox)`
  position: absolute;
  top: 130px;
  left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  padding: 14px 0;
`;

export const LargeProductContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 532px;
  gap: 32px;
  padding: 16px 32px 16px 8px;
`;

export const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LargeTagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`;

export const CharacterBlock = styled.div``;

export const LargePriceAndFormatWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 48px;
`;

export const PriceBlock = styled.div``;

export const LargePriceDashText = styled(Text)`
  text-decoration-line: line-through;
  white-space: nowrap;
  margin-right: 16px;
`;

export const IdentitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 32px auto 1fr;
  grid-template-rows: repeat(4, auto);
  align-items: center;
  gap: 12px 8px;
`;

export const IdentityImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const IdentityTitle = styled.span``;

export const LargeCtaWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: flex-end;
  justify-content: space-between;

  a,
  button {
    width: 100%;
  }
`;
