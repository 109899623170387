import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TCharacter } from '@lib/core/characters/types';
import { IParsedCharacter } from '@lib/core/characters/types/views';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';

import Button from '@components/web/src/atoms/Buttons/Button';
import ScanCTA from '@components/web/src/atoms/ScanCTA/ScanCTA';
import { B2B_MOBILE_RESOLUTION_MAX_VALUE } from '@components/web/src/kiosk/consts';
import GroupedProducts from '@components/web/src/kiosk/GroupedProducts/GroupedProducts';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import KioskContainer from '@components/web/src/kiosk/KioskContainer/KioskContainer';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import KioskCharacterDetailsComponent from '@components/web/src/organisms/KioskCharacterDetails/KioskCharacterDetails';

export type Props = {
  productCategory: TProductCategory;
  parsedCharacter: IParsedCharacter;
  products: TParsedProductInstance[];
  isProductsLoading: boolean;
  isEnableKioskFidelityScanAddon: boolean;
  isFidelityCardScanned: boolean;
  locale: string;
  storeType: TRetailerLocationStoreType;
  characterData: TCharacter;
  retailerFidelityCardImage: string;
  shouldHideWishlist?: boolean;
  shouldHideComment?: boolean;
  shouldHideFeedback?: boolean;
  navigateToProductCatalogPage: () => void;
};

const ResultPage: FC<Props> = ({
  productCategory,
  parsedCharacter,
  isEnableKioskFidelityScanAddon,
  isFidelityCardScanned,
  retailerFidelityCardImage,
  products,
  isProductsLoading,
  locale,
  storeType,
  characterData,
  shouldHideWishlist,
  shouldHideComment,
  shouldHideFeedback,
  navigateToProductCatalogPage,
}) => {
  const isMobileResolution = useMediaQuery({ maxWidth: B2B_MOBILE_RESOLUTION_MAX_VALUE });

  const { characterName } = parsedCharacter;
  const { resultPage } = localeKiosk;
  const { productCategoriesPlural } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];
  const titleText = characterData?.attributes?.[`kiosk_best_choice_label_${locale}`] || '';

  return (
    <KioskContainer>
      <div className="kiosk-result-page">
        <KioskCirclesBackground />
        <KioskCharacterDetailsComponent {...parsedCharacter} productCategory={productCategory} />
        {products?.length > 0 ? (
          <>
            <p className="page-title">{titleText}</p>
            <GroupedProducts
              isMobileResolution={isMobileResolution}
              isProductsLoading={isProductsLoading}
              products={products}
              shouldHideComment={shouldHideComment}
              shouldHideFeedback={shouldHideFeedback}
              shouldHideWishlist={shouldHideWishlist}
              storeType={storeType}
            />
            <Button
              className="result-page-btn"
              localeOptions={{ characterName, productCategory, productCategoryPluralText }}
              size="lg"
              text={resultPage.btn}
              handleClick={() => {
                MixpanelTracker.events.seeAllProducts();
                navigateToProductCatalogPage();
              }}
            />
          </>
        ) : (
          <NoProductsAvailable />
        )}
        {isEnableKioskFidelityScanAddon && !isFidelityCardScanned && (
          <div className="scan-cta-wrapper">
            <ScanCTA
              arrow
              description={resultPage.scanDescription}
              icon="fidelityCard"
              orientation="right"
              productCategory={productCategory}
              retailerFidelityCardImage={retailerFidelityCardImage}
              title={resultPage.scanTitle}
            />
          </div>
        )}
      </div>
    </KioskContainer>
  );
};

export default ResultPage;
