import { FC } from 'react';

import CircleColorKiosk from '@app/kiosk/src/components/CircleColorKiosk';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { promotionsFilter } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PROMOTION_LABEL_SLUG, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import tipStarIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/product_tip_star.svg';
import wishlistHeartIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/product_wishlist_heart.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/PropositionCard/styles';

export const PROPOSITION_CARD_VARIANTS = {
  tip: 'tip',
  wishlist: 'wishlist',
} as const;

interface IProps {
  isNotRoundedVariant: boolean;
  variant: keyof typeof PROPOSITION_CARD_VARIANTS;
  parsedProductInstanceData: TParsedProductInstance;
  productCategory: TProductCategory;
  wishlistQuantity?: number;
  navigateToTasteTest: () => void;
  navigateToProductPage: (productId: string) => void;
  navigateToProductCatalogPage?: (isEnableWishlistFilter?: boolean) => void;
}

export const PropositionCard: FC<IProps> = ({
  isNotRoundedVariant = false,
  parsedProductInstanceData,
  variant,
  wishlistQuantity = 0,
  productCategory,
  navigateToTasteTest,
  navigateToProductPage,
  navigateToProductCatalogPage,
}) => {
  const { publishedTerms } = LocaleUtils;

  const {
    wishlistTitleText,
    tipTitle,
    viewAll,
    nothingHere,
    notWorrySaveProducts,
    notWorryTakeTest,
    takeTest,
    viewCatalog,
  } = localeKiosk.homePageCards.productPropositionCard;
  const { commonMessages, productCategoriesPlural, productCard } = localeCommon;
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory]?.id];

  const {
    productInstanceId,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productProducerName,
    productFormat,
    productFormatName,
    productFormatSize,
    productDiscountPrice,
    promotions,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
  } = parsedProductInstanceData || {};

  const isShowPromoLabel = !!promotionsFilter(promotions, [PROMOTION_LABEL_SLUG]).length;

  const cardData = {
    [PROPOSITION_CARD_VARIANTS.tip]: {
      ctaBtnText: takeTest,
      ctaClickHandler: () => navigateToTasteTest(),
      headerClickHandler: () => {},
      icon: tipStarIcon,
      noWorryViewText: notWorryTakeTest,
      titleText: tipTitle,
    },
    [PROPOSITION_CARD_VARIANTS.wishlist]: {
      ctaBtnText: viewCatalog,
      ctaClickHandler: () => navigateToProductCatalogPage(),
      headerClickHandler: wishlistQuantity ? () => navigateToProductCatalogPage(true) : () => {},
      icon: wishlistHeartIcon,
      noWorryViewText: notWorrySaveProducts,
      titleText: wishlistTitleText,
    },
  };

  const getCardColor = (tipCardColor: string, wishlistCardColor?: string) =>
    variant === PROPOSITION_CARD_VARIANTS.tip ? tipCardColor || '' : wishlistCardColor || '';

  return (
    <S.ProductPropositionCardContainer
      $isNotRoundedVariant={isNotRoundedVariant}
      $productCategory={productCategory}
      $variant={variant}
    >
      <S.CardHeaderWrapper onClick={cardData[variant].headerClickHandler}>
        <S.CardHeaderIcon $productCategory={productCategory}>
          <img alt="icon" src={cardData[variant].icon} />
        </S.CardHeaderIcon>
        <Text
          color={getCardColor(STATIC_COLORS.base.white, STATIC_COLORS.base.black)}
          localeOptions={{ quantity: wishlistQuantity.toString() }}
          size="h6"
          text={cardData[variant].titleText}
          weight="semibold"
        />
        {!!wishlistQuantity && <S.ViewAllBtn fontSize="body2" text={viewAll} variant={VH_VARIANTS.LINK} />}
      </S.CardHeaderWrapper>
      {parsedProductInstanceData ? (
        <S.ProductDetailsViewWrapper onClick={() => navigateToProductPage(productInstanceId)}>
          {isShowPromoLabel && (
            <S.PromoIcon>
              <Text color={STATIC_COLORS.base.white} size="body3" text={commonMessages.promo} weight="semibold" />
            </S.PromoIcon>
          )}
          <S.ProductImageWrapper>
            <img alt={productName} src={productImage} />
            <CircleColorKiosk productCategoryColor={getCardColor(STATIC_COLORS.productColors[productCategory][500])} />
          </S.ProductImageWrapper>
          <Text
            color={STATIC_COLORS.base.black}
            linesLimit={1}
            size="body3"
            text={`#${productCharacterName} • ${productCharacterTechnicalCaption}`}
            weight="medium"
          />
          <S.ProductName
            color={getCardColor(STATIC_COLORS.base.white, STATIC_COLORS.productColors[productCategory][500])}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h5"
            text={productName}
            weight="semibold"
          />
          <S.ProducerName
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            linesLimit={1}
            size="body1"
            text={productProducerName}
            weight="semibold"
          />
          <S.ProductPriceFormatContainer>
            {productFormat && (
              <S.StyledProductFormatText
                color={getCardColor(STATIC_COLORS.base.white, STATIC_COLORS.base.black)}
                size="body2"
                text={`${productFormatName} ${productFormatSize && `, ${productFormatSize}`}`}
                weight="medium"
              />
            )}
            <S.ProductPriceContainer>
              {productDiscountPrice ? (
                <>
                  <S.StyledProductDashedPriceText
                    color={STATIC_COLORS.productColors[productCategory][500]}
                    size="h5"
                    text={showedProductOriginalPrice}
                  />
                  <S.StyledProductPriceText
                    color={getCardColor(STATIC_COLORS.base.white, STATIC_COLORS.base.black)}
                    size="h5"
                    text={showedProductDiscountPrice}
                    weight="semibold"
                  />
                </>
              ) : (
                <S.StyledProductPriceText
                  color={getCardColor(STATIC_COLORS.base.white, STATIC_COLORS.base.black)}
                  size="h5"
                  text={showedProductOriginalPrice}
                  weight="semibold"
                />
              )}
            </S.ProductPriceContainer>
          </S.ProductPriceFormatContainer>
          <S.GiveItBtn
            $variant={variant}
            fontSize="body2"
            size="sm"
            text={productCard.ctaGiveItBtn}
            variant={VH_VARIANTS.LINK}
            onClick={() => navigateToProductPage(productInstanceId)}
          />
        </S.ProductDetailsViewWrapper>
      ) : (
        <S.NoProductDetailsViewWrapper onClick={cardData[variant].ctaClickHandler}>
          <Text color={STATIC_COLORS.base.black} size="h5" text={nothingHere} weight="semibold" />
          <Text
            color={STATIC_COLORS.base.black}
            localeIndex={{ productCategory }}
            localeOptions={{ productCategoryPluralText }}
            size="subtitle2"
            text={cardData[variant].noWorryViewText}
            weight="medium"
          />
          <S.CtaBtn
            color={STATIC_COLORS.base.black}
            fontSize="subtitle2"
            size="lg"
            text={cardData[variant].ctaBtnText}
            variant={VH_VARIANTS.LINK}
          />
        </S.NoProductDetailsViewWrapper>
      )}
    </S.ProductPropositionCardContainer>
  );
};
