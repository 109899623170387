import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useRecipes } from '@lib/core/productAttributes/hooks';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { createMobileKioskQRUrl, prependBasename, promotionsFilter } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import RouteUtils from '@lib/tools/routes';
import {
  CHARACTERS_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  PROMOTION_LABEL_SLUG,
} from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import ProductPage from '@components/web/src/templates/ProductPage/Kiosk/ProductPage';

const ProductPageContainer: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { isUserHasAnyCharacter } = useUser();

  const isFromKioskQR = !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM);
  const { isFidelityCardScanned } = useFidelityCard();
  const { parseProduct } = useParsedProducts();
  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const parsedProductInstanceData = parseProduct(productInstanceData);
  const { productCharacterId, productCategory, productFormatIdentifier } = parsedProductInstanceData || {};

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);
  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { discoveryQuiz } = useDiscoveryQuizData();

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const mobileKioskQRUrl = createMobileKioskQRUrl();

  const onBackButtonClick = () => {
    const paramSeparator = locationState?.from?.includes('?') ? '&' : '?';
    const url = `${locationState?.from}${paramSeparator}${IS_FROM_PRODUCT_DETAILS_PAGE}=true`;

    return isFromKioskQR || !locationState?.from ? navigate(prependBasename(PAGES.vinhood.catalog)) : navigate(url);
  };

  const navigateToCatalog = () =>
    navigate(prependBasename(PAGES.vinhood.catalog, { [CHARACTERS_URL_PARAM]: productCharacterId }));

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  // We don't reset catalog/filter data if we go from catalog to product page
  // That's why we should do it here, in case if we are navigating from product page to another page (not catalog)
  useEffect(() => {
    return () => {
      if (!RouteUtils.getPage().includes(PAGES.vinhood.catalog)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
      }
    };
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ProductPage
      discoveryQuiz={discoveryQuiz}
      isShowPromoLabel={!!promotionsFilter(productInstanceData?.promotions, [PROMOTION_LABEL_SLUG]).length}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      mobileKioskQRUrl={mobileKioskQRUrl}
      navigateToCatalog={navigateToCatalog}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      parsedProductInstanceData={parsedProductInstanceData}
      productCategory={productCategory}
      recipesData={recipesData}
      shouldHideWishlist={!isFidelityCardScanned}
      sustainabilityData={sustainabilityData}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
