import cn from 'classnames';
import { FC, useLayoutEffect } from 'react';
import { animated, config, useSpring } from 'react-spring';

import { TCharacter } from '@lib/core/characters/types';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import ScanCTA from '@components/web/src/atoms/ScanCTA/ScanCTA';
import AnonymousCards from '@components/web/src/kiosk/Cards/AnonymousCards/AnonymousCards';
import TasteTestCards from '@components/web/src/kiosk/Cards/TasteTestCards/TasteTestCards';
import UserTasteCards from '@components/web/src/kiosk/Cards/UserTasteCards/UserTasteCards';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import KioskContainer from '@components/web/src/kiosk/KioskContainer/KioskContainer';
import HeroSection from '@components/web/src/organisms/HeroSection/HeroSection';

interface IProps {
  retailerPicture: string;
  productCategory: TProductCategory;
  productQuantity: number;
  characterData: TCharacter;
  retailerFidelityCardImage: string;
  isEnableKioskFidelityScanAddon: boolean;
  isFidelityCardScanned: boolean;
  isFidelityCardLoading: boolean;
  isLoadingData: boolean;
  isAnonymousPreference: boolean;
  mobileKioskQRUrl: string;
  tipParsedProductInstanceData: TParsedProductInstance;
  wishlistQuantity: number;
  isPromotionProductsAvailable: boolean;
  wishlistParsedProductInstanceData: TParsedProductInstance;
  onAnonymousCardClick: () => void;
  onPromotionCardClick: () => void;
  navigateToProductCatalogPage: (isEnableWishlistFilter?: boolean) => void;
  navigateToProductCatalogPageWithoutCharacter: () => void;
  navigateToTasteTest: () => void;
  navigateToSituationalTest: () => void;
  navigateToProductPage: (productId: string) => void;
}

const HomePage: FC<IProps> = ({
  productCategory,
  productQuantity,
  retailerPicture,
  characterData,
  retailerFidelityCardImage,
  isEnableKioskFidelityScanAddon,
  isFidelityCardScanned,
  isFidelityCardLoading,
  isAnonymousPreference,
  mobileKioskQRUrl,
  tipParsedProductInstanceData,
  isPromotionProductsAvailable,
  wishlistQuantity,
  wishlistParsedProductInstanceData,
  isLoadingData,
  navigateToProductPage,
  onAnonymousCardClick,
  onPromotionCardClick,
  navigateToProductCatalogPage,
  navigateToTasteTest,
  navigateToSituationalTest,
  navigateToProductCatalogPageWithoutCharacter,
}) => {
  const { commonMessages, productCategories } = localeCommon;
  const { homeQRSection } = localeKiosk;
  const { publishedTerms } = LocaleUtils;
  const productCategoryText = publishedTerms[productCategories[productCategory]?.id];

  const isFidelityAddonWelcomeView =
    isEnableKioskFidelityScanAddon && !isFidelityCardScanned && !isFidelityCardLoading && !isAnonymousPreference;
  const isFidelityAddonAnonymousView =
    isEnableKioskFidelityScanAddon && !isFidelityCardScanned && !isFidelityCardLoading && isAnonymousPreference;
  const shouldDisplaySmallVariant = isFidelityCardScanned || isFidelityCardLoading;

  useLayoutEffect(() => {
    document.querySelector('#root-element')?.scrollTo(0, 0);
  }, [isFidelityCardScanned]);

  const tasteTestCardsAnimation = useSpring({
    config: config.slow,
    opacity: isFidelityAddonAnonymousView || !isEnableKioskFidelityScanAddon ? 1 : 0,
  });

  const noFidelityAddonWelcomeViewAnimation = useSpring({
    config: config.slow,
    opacity: !isFidelityAddonWelcomeView ? 1 : 0,
  });

  return (
    <KioskContainer>
      <div className="home-page-kiosk-container">
        <KioskCirclesBackground />
        <HeroSection
          productCategory={productCategory}
          productQuantity={productQuantity}
          retailerBackgroundImage={retailerPicture}
          shouldDisplaySmallVariant={shouldDisplaySmallVariant}
        />
        <div
          className={cn('cards-section', {
            isFidelityCardScanned,
          })}
        >
          {isFidelityAddonWelcomeView && (
            <AnonymousCards handleClick={onAnonymousCardClick} retailerFidelityCardImage={retailerFidelityCardImage} />
          )}
          {(!isEnableKioskFidelityScanAddon || isFidelityAddonAnonymousView) && (
            <animated.div style={tasteTestCardsAnimation}>
              <TasteTestCards
                navigateToSituationalTest={navigateToSituationalTest}
                navigateToTasteTest={navigateToTasteTest}
                productCategory={productCategory}
              />
            </animated.div>
          )}
          {shouldDisplaySmallVariant && (
            <UserTasteCards
              characterData={characterData}
              isLoading={isLoadingData}
              isPromotionProductsAvailable={isPromotionProductsAvailable}
              navigateToProductCatalogPage={navigateToProductCatalogPage}
              navigateToProductPage={navigateToProductPage}
              navigateToSituationalTest={navigateToSituationalTest}
              navigateToTasteTest={navigateToTasteTest}
              productCategory={productCategory}
              tipParsedProductInstanceData={tipParsedProductInstanceData}
              wishlistParsedProductInstanceData={wishlistParsedProductInstanceData}
              wishlistQuantity={wishlistQuantity}
              onPromotionCardClick={onPromotionCardClick}
            />
          )}
        </div>
        {!isFidelityAddonWelcomeView && (
          <animated.div className="qr-section" style={noFidelityAddonWelcomeViewAnimation}>
            <Button
              className="catalog-button"
              localeOptions={{ productCategory, productCategoryText }}
              size="lg"
              text={commonMessages.catalog}
              variant={VH_VARIANTS.LINK}
              onClick={() => {
                MixpanelTracker.events.productCatalogClick();
                navigateToProductCatalogPageWithoutCharacter();
              }}
            />
            <div className="scan-cta-wrapper">
              <ScanCTA
                description={homeQRSection.subTitleMobile}
                orientation="left"
                productCategory={productCategory}
                qrCodeUrl={mobileKioskQRUrl}
                title={homeQRSection.titleMobile}
              />
              <ScanCTA
                arrow
                description={homeQRSection.subTitleScan}
                icon="product"
                orientation="right"
                productCategory={productCategory}
                title={homeQRSection.titleScan}
              />
            </div>
          </animated.div>
        )}
      </div>
    </KioskContainer>
  );
};

export default HomePage;
